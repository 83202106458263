import React from 'react';
import loadable from '@loadable/component';

const AnimatedHexCardDeck = loadable(() =>
  import('./variants/AnimatedHexCardDeck')
);
const CardDeckDefault = loadable(() => import('./variants/DefaultCardDeck'));
const CardDeckSlider = loadable(() => import('./variants/SliderCardDeck'));
const SolutionsCardDeck = loadable(() =>
  import('./variants/SolutionsCardDeck')
);
const SolutionsV2CardDeck = loadable(() => import("./variants/SolutionsV2CardDeck"))
const RecommendedResourcesSlider = loadable(() =>
  import('./variants/ResourcesSliderCardDeck')
);
const ResourcesCardDeck = loadable(() =>
  import('./variants/ResourcesCardDeck')
);
const PricingCardDeck = loadable(() => import('./variants/PricingCardDeck'));

/**
 * Card Deck Variants
 * Animated Hex
 * Default
 * Default - Wide
 * Slider
 * Solutions
 * Recommended Resources Slider
 * Resources
 */

const CardDeck = ({ component, ...rest }) => {
  const { variant } = component;
  switch (variant) {
    case 'animated-hex':
      return <AnimatedHexCardDeck component={component} />;
    case 'default':
      return <CardDeckDefault component={component} />;
    case 'default-wide':
      return <CardDeckDefault component={component} wide />;
    case 'recommended-resources-slider':
      return <RecommendedResourcesSlider />;
    case 'resources':
      return <ResourcesCardDeck component={component} />;
    case 'slider':
      return <CardDeckSlider component={component} />;
    case 'solutions':
      return <SolutionsCardDeck component={component} />;
    case 'solutionsv2':
      return <SolutionsV2CardDeck component={component} />;
    case 'pricing':
      return <PricingCardDeck component={component} {...rest} />;
    default:
      return <CardDeckDefault component={component}  />;
  }
};

export default CardDeck;
